import React from "react";
import { Container, Col, Row } from "reactstrap";
import homeImg from "../assets/img/homeImg.png";
import tick from "../assets/img/tick.svg";
// import iVector from "../assets/img/i-vector.png";
import Arrow from "../assets/img/Arrow.svg";
import sticker from "../assets/img/stickerOrange.png";
import stickersm from "../assets/img/stickersm.png";

function UIOne({ step, setStep }) {
    console.log(step);
    return (
        <Container fluid>
            <Row>
                <Col md={12} lg={6} className=" h-auto  px-0 hero-contents__bg">
                    <Row className="w-100 mx-0 my-">
                        <Col md={3}></Col>
                        <Col
                            md={8}
                            className="py-md-5 py-0 px-lg-0 px-sm-5 px-0  d-flex justify-content-center flex-column position-relative"
                        >
                            <h1 className="fw-bold hero-contents__title mb-4">
                                Goede voornemens onderzoek 2024
                            </h1>
                            <p className="hero-contents__text ">
                                <b>
                                    “Mijn goede voornemen is om te stoppen met
                                    roken”
                                </b>
                            </p>
                            <p className="hero-contents__text">
                                Dat is geweldig! Wist je dat wij jou GRATIS
                                kunnen helpen? Doe nu mee met ons onderzoek en
                                ontvang uitgebreid advies hoe je succesvol stopt
                                met roken!
                            </p>

                            <p className="mt-3 hero-contents__smtext2">
                                <b>Het onderzoek duurt maar 2 minuten.</b>
                            </p>

                            <button
                                className="hero-contents__btn d-flex justify-content-around fw-700 align-items-center pe-2 ps-4 mb-3"
                                onClick={() => {
                                    setStep(1);
                                }}
                            >
                                START DEELNAME
                                <img src={Arrow} alt="" />
                            </button>
                            <hr />
                            <div
                                className="d-flex justify-content-center
    align-items-baseline"
                            >
                                <div className="d-flex align-items-baseline justify-content-center">
                                    <div>
                                        <img src={tick} alt="" />
                                    </div>
                                </div>
                                <small className="ms-3 hero-contents__smtext">
                                    Het doel van onze stichting is om mensen te
                                    adviseren bij het stoppen met roken. Wij
                                    hebben geen winstoogmerk.
                                </small>
                            </div>
                            {/* <img
                                src={sticker}
                                className="hero-contents__sticker d-sm-flex d-none"
                            />
                            <img
                                src={stickersm}
                                className="hero-contents__sticker d-sm-none d-flex"
                            /> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} lg={6} className="px-0 hero-contents__img">
                    <img src={homeImg} alt="" />
                </Col>
            </Row>
        </Container>
    );
}
export default UIOne;
