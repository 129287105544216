import { RepositoryFactory } from "../../repository/RepositoryFactory";
var lead = RepositoryFactory.get("leads");

export const createLead =
    (payload, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        try {
            // alert("jkg");
            dispatch({ type: "LEAD_LOADER", payload: true });

            const { data } = await lead.postLead(payload);
            dispatch({ type: "PROFILE", payload: data });
            onSuccess();
        } catch (error) {
            console.log(error.response.data.error);
            dispatch({
                type: "LEAD_ERROR",
                payload: error.response.data.error,
            });
            onSuccess();
            // console.log(error.response.data.error);
        }
    };
