const initialState = {
    profile: null,
    isLoading: false,
    error: null,
};

export default function leadProfile(state = initialState, { type, payload }) {
    switch (type) {
        case "PROFILE":
            return {
                ...state,
                profile: payload,
                isLoading: false,
                error: null,
            };
        case "LEAD_LOADER":
            return {
                ...state,
                isLoading: payload,
            };
        case "LEAD_ERROR":
            return {
                ...state,
                error: payload,
                isLoading: false,
            };
        default:
            return state;
    }
}
