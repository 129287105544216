import React from "react";
import { Container, Col, Row } from "reactstrap";
import logo from "../assets/img/logo.svg";
import stars from "../assets/img/stars.png";
function Header() {
    return (
        <Container fluid>
            <Row className="d-flex  align-items-center header py-md-0 py-2">
                <Col md={8} className="m-auto">
                    <Row>
                        <Col></Col>
                        <Col lg={10}>
                            <div className="d-flex align-items-center justify-content-start header__logo my-md-2 my-0">
                                <div>
                                    <img src={logo} alt="" />
                                </div>
                                <span className="mt-4  header__tag">
                                    Nationaal Rokers Onderzoek 2024{" "}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} className="header__main">
                    <div className="d-flex align-items-center justify-content-center header__stars">
                        <span>4.3/5</span>
                        <div>
                            <img src={stars} alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default Header;
